import moment from 'moment-timezone';
import PostalCodes from 'postal-codes-js';
import {
  isValidPhoneNumber,
  parsePhoneNumber as parsePhoneNumberAPI
} from 'react-phone-number-input';
import i18n from '../i18n';

import ctaHeatPumpInitialValues from './DevicesTable/utils/ctaHeatPumpInitialValues';
import { convertTimeIntoMinutes } from '../components/ReduxFormFields/SwitchingTimes/helper';
import { ctaHeatPump, ctaHeatPumpCarel, ctaHeatPumpRTU } from './DevicesTable/constants';
import { isDeutsch } from '../utils/isDeutsch';

/**
 * @module FunctionsForReduxForm
 */

// ** Required ** //

/**
 * Validates required fields
 * @function required
 * @memberof module:FunctionsForReduxForm
 * @param {string} value Value of field
 * @returns {string|undefined} Returns string with error. Or undefined if value is valid.
 */
export const required = (value) => (typeof value !== 'undefined' && value !== null && value !== '' ? undefined : 'required');

export const getConnectedToProviderValidation = (initialProvider) => (_value, values) => {
  if (values.connectedTo && values.connectedTo !== values.provider) {
    return 'required';
  }

  if (!values.connectedTo && initialProvider !== values.provider) {
    return 'required';
  }

  return undefined;
};

/**
 * Validates required values of days
 * @function requiredDays
 * @memberof module:FunctionsForReduxForm
 * @param {array|undefined} days Array of days switching option
 * @returns {string|undefined} Returns string with error. Or undefined if value is valid.
 */
export const requiredDays = (days) => (days?.every((option) => option === 0) ? 'required' : undefined);

/**
 * Validates required multiple values fields
 * @function requiredMultiple
 * @memberof module:FunctionsForReduxForm
 * @param {Object[]} value Array of values
 * @returns {string|undefined} Returns string with error. Or undefined if value is valid.
 */
export const requiredMultiple = (value) => (Array.isArray(value) && value.length > 0 ? undefined : 'required');

/**
 * Validates required fields, with custom warning
 * @function requiredCustomWarning
 * @memberof module:FunctionsForReduxForm
 * @param {string} customWarning Warning message
 * @returns {function} Validation function
 */
export const requiredCustomWarning = (customWarning) => (/** @type {string} */ value) => (typeof value !== 'undefined' && value !== null && value !== '' ? undefined : customWarning);

/**
 * Low resolution value
 * @constant {number}
 */
export const LOW_RESOLUTION_VALUE = 4;
export const DEFAULT_RESOLUTION_VALUE = 1;

/**
 * Validates float string
 * @param {string} value - input valule
 * @returns {undefined|string} - Validation error message or undefined
 */
export const validateFloat = (value) => {
  const regex = isDeutsch() ? /^\d+(\,\d{0,1})?$/ : /^\d+(\.\d{0,1})?$/;
  return regex.test(value) ? undefined : 'float.validationError';
};

export const validateFloatWithTwoDecimal = (value) => {
  const regex = isDeutsch() ? /^\d+(\,\d{0,2})?$/ : /^\d+(\.\d{0,2})?$/;
  return regex.test(value) ? undefined : 'float.validationError2';
};

export const minFloat = (min, error) => (value) => {
  const numberValue = parseFloat(value);

  return numberValue >= min ? undefined : error ?? 'float.validationError';
};

export const validateFloatWithThreeDecimalForFloat = (value) => {
  const regex = /^\d+(\.\d{0,3})?$/g;
  return regex.test(value) ? undefined : 'float.validationError';
};

export const validateFloatWithThreeDecimal = (value) => {
  const regex = isDeutsch() ? /^\d+(\,\d{0,3})?$/g : /^\d+(\.\d{0,3})?$/g;
  return regex.test(value) ? undefined : 'incorrectPriceMessage';
};

/**
 * Validates offset for general settings
 * @memberof module:FunctionsForReduxForm
 * @param {string} value Value string
 * @returns {string|undefined} Returns string with error. Or undefined if value is valid.
 */
export const offset = (value) => (value
  && !/^0*([0-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])$/.test(
    value
  )
  ? 'positiveIntValue'
  : undefined);

/**
 * Validates if a value of the field is greater than min and less than max
 * @memberof module:FunctionsForReduxForm
 * @param {string} min Min value
 * @param {string} max Max value
 * @param {string} errorMessage String with error
 * @returns {function} Returns function that return string with error. Or undefined if value is valid.
 */
const getCheckInRangeFunc = (min, max, errorMessage = 'rangeValidationErrorMessage') => (value) => (value >= min && value <= max ? undefined : i18n.t(errorMessage).replace('{MIN}', min).replace('{MAX}', max));

export const betweenPlusMinus2 = getCheckInRangeFunc(-2, 2);
export const from100to9000 = getCheckInRangeFunc(100, 9000);
export const from0to100 = getCheckInRangeFunc(0, 100);
export const from1to100 = getCheckInRangeFunc(1, 100);
export const from6to32 = getCheckInRangeFunc(6, 32);
export const from0to7 = getCheckInRangeFunc(0, 7);
export const fromMinus2to0 = getCheckInRangeFunc(-2, 0);
export const from0to2 = getCheckInRangeFunc(0, 2);
export const from0to10 = getCheckInRangeFunc(0, 10);
export const from10to100 = getCheckInRangeFunc(10, 100);
/**
 * Returns function that validates if value of field is less then given number
 * @memberof module:FunctionsForReduxForm
 * @param {number} max Max length of string value
 * @returns {function} Validation function
 */
export const maxLength = (max) => (/** @type {string | any[]} */ value) => (typeof value === 'string' && value.length > max
  ? `${i18n.t('least')} ${max} ${i18n.t('symbols')}`
  : undefined);

/**
 * Returns function that validates if value of field is greater then given number
 * @memberof module:FunctionsForReduxForm
 * @param {number} min Min length of string value
 * @returns {function} Validation function
 */
export const minLength = (min) => (/** @type {{ trim: any }} */ value) => (typeof value === 'string' && value.trim().length > 0 && value.trim().length < min
  ? `${i18n.t('least')} ${min} ${i18n.t('symbols')}`
  : undefined);

/**
 * Parses positive integer or zero values
 * @memberof module:FunctionsForReduxForm
 * @param {string} value String value
 * @returns {null|number} String of numbers
 */
export const parsePositiveIntegerOrZero = (value) => {
  const pureValue = value.replace(/[^0-9]/g, '');
  const newValue = Number.parseInt(pureValue, 10);
  return Number.isNaN(newValue) ? null : newValue;
};

/**
 * Parses only positive integer values or zero
 * @memberof module:FunctionsForReduxForm
 * @param {string} value String value
 * @returns {number} String of numbers
 */
export const parseOnlyPositiveIntegerOrZero = (value) => {
  const pureValue = value.replace(/[^0-9]/g, '');
  const newValue = Number.parseInt(pureValue, 10);
  return Number.isNaN(newValue) ? 0 : newValue;
};

/**
 * Validates if a field is integer
 * @function toBeInteger
 * @memberof module:FunctionsForReduxForm
 * @param {string} value Value string
 * @returns {string|undefined} Returns string with error. Or undefined if value is valid.
 */
export const toBeInteger = (/** @type {unknown} */ value) => (Number.isInteger(value) ? undefined : 'required');

/**
 * Parses only numbers
 * @memberof module:FunctionsForReduxForm
 * @param {string} value String value
 * @returns {string} String of numbers
 */
export const parseStringOfNumbers = (value = '') => value.replace(/[^\d]/g, '');

/**
 * Parses only numbers for Devices fields
 * @memberof module:FunctionsForReduxForm
 * @param {string} value String value
 * @returns {number} String of numbers
 */
export const parseOnlyNumbers = (value) => {
  const newValue = Number.parseInt(value, 10);
  return Number.isNaN(newValue) ? null : newValue;
};

/**
 * Parses only positive or zero numbers for Devices fields
 * @memberof module:FunctionsForReduxForm
 * @param {number} value String value
 * @returns {number} String of numbers
 */
export const parseOnlyPositiveNumbers = (value) => Math.abs(value) || 0;

/**
 * Parses only positive or nothing for Devices fields
 * @memberof module:FunctionsForReduxForm
 * @param {number} value String value
 * @returns {number|null|''}
 */
export const parsePositiveNumbersOrNothing = (value) => Math.abs(value) || '';

/**
 * Parses only negative or zero numbers for Devices fields
 * @memberof module:FunctionsForReduxForm
 * @param {number} value String value
 * @returns {number} String of numbers
 */
export const parseOnlyNegativeNumbers = (value) => -Math.abs(value) || 0;

/**
 * Parses only positive numbers for Devices fields
 * @memberof module:FunctionsForReduxForm
 * @param {number} value String value
 * @returns {number} String of numbers
 */
export const parseStrictPositiveNumbers = (value) => Math.abs(value) || undefined;

/**
 * Parses positive numbers and zero for Devices fields
 * @memberof module:FunctionsForReduxForm
 * @param {number} value String value
 * @returns {number} String of numbers
 */
export const parsePositiveAndZeroNumbers = (value) => Math.abs(value) || 0;

/**
 * Parses only letters and numbers
 * @memberof module:FunctionsForReduxForm
 * @param {string} value String value
 * @returns {string} String of numbers
 */
export const parseStringOfLettersAndNumbers = (value) => value.replace(/[^a-zA-Z0-9\\-]/g, '');

/**
 * Parses negative and positive integer numbers for Devices fields
 * @memberof module:FunctionsForReduxForm
 * @param {string} value String value
 * @returns {number|string} String of numbers and hyphens
 */
export const parseIntegerNumbers = (value) => {
  const pureValue = value.replace(/[^0-9-]/g, '');
  const newValue = Number.parseInt(pureValue, 10);
  return Number.isNaN(newValue) ? pureValue : newValue;
};

/**
 * Parses negative and positive float numbers for Devices fields
 * @memberof module:FunctionsForReduxForm
 * @param {string} value String value
 * @returns {string} String of numbers
 */
export const parseFloatNumbersWithOneDigitAfterPoint = (value) => value.replace(isDeutsch() ? /[^\d\,]/g : /[^\d\.]/g, '');

/**
 * Parses only positive integer numbers for Devices fields
 * @memberof module:FunctionsForReduxForm
 * @param {string} value String value
 * @returns {number} String of numbers
 */
export const parseOnlyPositiveIntegerNumbers = (value) => {
  const pureValue = value.replace(/[^0-9]/g, '');
  const newValue = Number.parseInt(pureValue, 10);
  return Number.isNaN(newValue) || newValue === 0 ? null : newValue;
};

/**
 * Parser. Parses integer numbers for Devices fields.
 * Recommended using with validators, as it is not a strict parser.
 * @memberof module:FunctionsForReduxForm
 * @param {string} value String value
 * @returns {string|number} String of numbers
 */
export const parseIntegerNumbersNotStrict = (value) => {
  const isValid = /^(-[1-9]+|[0-9]+)$/.test(value);
  const parsedValue = Number.parseInt(value, 10);
  return isValid && Number.isSafeInteger(parsedValue) ? parsedValue : value;
};

/**
 * Validate if key is number
 * @param {string|number} key
 * @returns {boolean}
 */
export const checkNotNumber = (/** @type {string} */ key) => /^\D$/.test(key);

/**
 * Validator. Only negative integer values and 0 are allowed.
 * @param {string} value
 * @returns {string|undefined}
 */
export const onlyZeroAndLowerNegativesInteger = (value) => {
  const isValid = /^(-[1-9]+|0)$/.test(value);
  const parsedValue = Number.parseInt(value, 10);
  return isValid && Number.isSafeInteger(parsedValue) && parsedValue <= 0
    ? undefined
    : 'onlyZeroAndLowerNegatives';
};
export const onlyZeroAndHigherPositives = (value) => {
  const isValid = /^[0-9]*$/.test(value);
  const parsedValue = Number.parseInt(value, 10);
  return isValid && Number.isSafeInteger(parsedValue) && parsedValue >= 0
    ? undefined
    : 'onlyZeroAndHigherPositives';
};

/**
 * Returns function that normalize value of field with given number
 * @memberof module:FunctionsForReduxForm
 * @param  {number} length
 * @returns {function} Normalize function
 */
export const normalizeLength = (length) => {
  if (Number.isSafeInteger(length)) {
    return (/** @type {string | any[]} */ value) => value.slice(0, length);
  }
  return (/** @type {string | any[]} */ value) => value.slice(0, Number.MAX_SAFE_INTEGER);
};

/**
 * Validates if a field is integer positive and equal or more than one
 * @function toBeOneOrPositive
 * @memberof module:FunctionsForReduxForm
 * @param {string} value Value string
 * @returns {string|undefined} Returns string with error. Or undefined if value is valid.
 */
export const toBeOneOrPositive = (/** @type {string} */ value) => (Number.parseInt(value, 10) >= 1 ? undefined : 'onlyAllowOndAndPositive');

// ** Phone ** //

/**
 * Validates phone number
 * @function phoneNumber
 * @memberof module:FunctionsForReduxForm
 * @param {string} value Value of field
 * @returns {string|undefined} Returns string with error. Or undefined if value is valid.
 */
export const phoneNumber = (value) => (value && !/^\d.{1,50}$/.test(value.toString().replace(/-/gi, '')) ? 'invalidPhone' : undefined);

/**
 * Parses phone number to valid format like (xxx-xxx-xx-xx)
 * @memberof module:FunctionsForReduxForm
 * @param {string} value String value
 */
export const parsePhoneNumber = (value) => {
  if (!value) return value;
  const cleanedValue = `${value}`.replace(/\D/g, '');
  const cleanedValueLength = cleanedValue.length;
  let newValue = cleanedValue;
  if (cleanedValueLength > 3 && cleanedValueLength <= 6) {
    newValue = `${cleanedValue.slice(0, 3)} ${cleanedValue.slice(3)}`;
  } else if (cleanedValueLength > 6 && cleanedValueLength <= 8) {
    newValue = `${cleanedValue.slice(0, 3)} ${cleanedValue.slice(3, 6)} ${cleanedValue.slice(6)}`;
  } else if (cleanedValueLength > 8 && cleanedValueLength <= 10) {
    newValue = `${cleanedValue.slice(0, 3)} ${cleanedValue.slice(3, 6)} ${cleanedValue.slice(
      6,
      8
    )} ${cleanedValue.slice(8, 10)}`;
  } else if (cleanedValueLength > 10) {
    newValue = `${cleanedValue.slice(0, 3)} ${cleanedValue.slice(3, 6)} ${cleanedValue.slice(
      6,
      8
    )} ${cleanedValue.slice(8, 10)}${cleanedValue.slice(10)}`;
  }
  return newValue;
};

/**
 * Validates phone number
 * @param {string} value
 * @returns {string|undefined}
 */
export const checkValidPhone = (value) => {
  if (!value) return undefined;
  const number = parsePhoneNumberAPI(`+${parseStringOfNumbers(value)}`)?.number;
  if (number) return isValidPhoneNumber(number) ? undefined : 'invalidPhone';
  return 'invalidPhone';
};

// ** Time ** //

/**
 * Validates if a field is NOT equal to another field
 * @function notEqualsTime
 * @memberof module:FunctionsForReduxForm
 * @param {string} fieldName Name of another field
 * @returns {function} Validation function
 */
export const notEqualsTime = (fieldName) => (/** @type {any} */ value, /** @type {{ [x: string]: any; }} */ values) => {
  const compareValue = values[fieldName];
  return value && value !== compareValue ? undefined : 'equalTime';
};

/**
 * Validates time
 * @memberof module:FunctionsForReduxForm
 * @param {string|*} from Value string from
 * @param {string|*} to Value string to
 * @returns {string|undefined} Returns string with error. Or undefined if value is valid.
 */
export const validateFromLessOrEqualToTo = (
  /** @type {string} */ from,
  /** @type {string} */ to
) => {
  if (typeof from !== 'string' || typeof to !== 'string') {
    return undefined;
  }
  // eslint-disable-next-line arrow-body-style
  const fromInMinutes = convertTimeIntoMinutes(from);
  const toInMinutes = convertTimeIntoMinutes(to);
  return fromInMinutes < toInMinutes ? undefined : 'From should be less than to';
};

/**
 * Normalized time string
 * @memberof module:FunctionsForReduxForm
 * @param {string} value String value
 * @param {string} previousValue Value before latest change
 */
export const normalizeTime = (value, previousValue) => {
  if (!value) {
    return value;
  }
  // const newValueArray = value.match(/(24(:0{0,2})?)|((2[0-3]|[0-1][0-9]|[0-9])(:[0-5][0-9]|:[0-5]|:?))/i); IF NEED TO ALLOW ALSO 24:00
  const newValueArray = value
    .replace(/[^\d\:]/g, '')
    .match(/(2[0-3]|[0-1][0-9]|[0-9])(:[0-5][0-9]|:[0-5]|:?)/i);

  if (newValueArray !== null) {
    const newValue = newValueArray[0];
    if (newValue.length === 1 && Number(newValue[0]) > 2) {
      return !previousValue || previousValue[1] !== ':' ? `0${newValue}:` : newValue;
    }
    if (newValue.length === 2) {
      if (newValue[1] === ':') {
        return `0${newValue}`;
      }
      return !previousValue || previousValue[2] !== ':' ? `${newValue}:` : newValue;
    }
    return newValue;
  }
  return null;
};

/**
 * Parse time string
 * @memberof module:FunctionsForReduxForm
 * @param {string} value String value
 * @returns {string} String value in format of 'hh:mm'
 */
export const timeParser = (value) => {
  const timeStr = value.replace(/[^\d\:]/g, '');
  const [hoursStr = '', minutesStr = ''] = timeStr.split(':');
  const rawHours = hoursStr.slice(0, 2);
  const rawMinutes = minutesStr.slice(0, 2);

  if (!rawHours && !rawMinutes) {
    return '';
  }

  if (rawHours && !rawMinutes) {
    if (+rawHours < 3) {
      if (rawHours.length <= 1) {
        return rawHours;
      }
      return `${rawHours}:`;
    }
    if (+rawHours > 2 && +rawHours < 24) {
      return `${rawHours.padStart(2, '0')}:`;
    }
    return `${rawHours[0].padStart(2, '0')}:`;
  }

  if (!rawHours && rawMinutes) {
    if (+rawMinutes < 6) {
      return `:${rawMinutes}`;
    }
    if (+rawMinutes > 5 && +rawMinutes < 60) {
      return `:${rawMinutes.padStart(2, '0')}`;
    }
    return `:${rawMinutes[0].padStart(2, '0')}`;
  }

  const hours = +rawHours[0] < 3 && +rawHours < 24 ? rawHours : rawHours[0].padStart(2, '0');
  const minutes = +rawMinutes[0] < 6 && +rawHours < 60 ? rawMinutes : rawMinutes[0].padStart(2, '0');

  return `${hours}:${minutes}`;
};

/**
 * Validates time
 * @memberof module:FunctionsForReduxForm
 * @param {string} value Value string
 * @returns {string|undefined} Returns string with error. Or undefined if value is valid.
 */
export const time = (value) => (value && !/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value) ? 'invalidTime' : undefined);

/**
 * Validates required values of days
 * @function requiredGeneralSwitchingTimes
 * @memberof module:FunctionsForReduxForm
 * @param {array|undefined} listOfSwitchingTimes Array of switching times object
 * @returns {Array|undefined} Returns array of objects with error strings or undefined if value is valid.
 */
export const requiredGeneralSwitchingTimes = (listOfSwitchingTimes) => {
  // eslint-disable-next-line arrow-body-style
  const arrOfErrors = listOfSwitchingTimes?.map(
    ({
      from,
      to,
      switchMode,
      switchAction,
      activeDays,
      constantCurrentSetting,
      minimumChargeQuantityTargetAmount,
      intelligentStartTime
    }) => {
      const fromError = required(from) || time(from) || validateFromLessOrEqualToTo(from, to);
      const toError = required(to) || time(to) || validateFromLessOrEqualToTo(from, to);
      const daysError = requiredDays(activeDays);
      const constantCurrentSettingError = constantCurrentSetting !== undefined && (required(constantCurrentSetting) || from6to32(constantCurrentSetting));
      const minimumChargeQuantityTargetAmountError = switchMode === 6 && (required(minimumChargeQuantityTargetAmount) || from1to100(minimumChargeQuantityTargetAmount));
      const intelligentStartTimeError = intelligentStartTime !== undefined && (required(intelligentStartTime) || time(intelligentStartTime));
      let actionError;
      if (switchMode) {
        actionError = required(switchMode);
      }
      if (switchAction) {
        actionError = required(switchAction);
      }

      return fromError || toError || daysError || actionError || constantCurrentSettingError || minimumChargeQuantityTargetAmountError || intelligentStartTimeError
        ? { fromError, toError, daysError, actionError, constantCurrentSettingError, minimumChargeQuantityTargetAmountError, intelligentStartTimeError }
        : undefined;
    }
  );
  return arrOfErrors?.every((option) => option === undefined) ? undefined : arrOfErrors;
};

// ** Date ** //

/**
 * Validates date
 * @param {string} value input value
 * @returns {undefined|string}
 */
export const validateDate = (value) => (moment(value).isValid() ? undefined : i18n.t('invalidDateFormatValidationError'));

/**
 * Validates two dates, if 'from' date is same or before 'to'
 * @param {string|number} from From date... Example: 12/12/2021
 * @param {string|number} to To date... Example 31/12/2021
 * @returns {undefined|string}
 */
export const validateSameOrBeforeDate = (from, to) => {
  const fromDate = moment(from);
  const toDate = moment(to);

  if (!from || !to || !fromDate.isValid() || !toDate.isValid()) {
    return undefined;
  }

  return fromDate.isSameOrBefore(toDate) ? undefined : i18n.t('fromDateIsAfterValidationError');
};

/**
 * Validates two dates, if 'to' date is same or after 'from'
 * @param {string|number} from From date... Example: 12/12/2021
 * @param {string|number} to To date... Example 31/12/2021
 * @returns {undefined|string}
 */
export const validateSameOfAfterDate = (from, to) => {
  const fromDate = moment(from);
  const toDate = moment(to);

  if (!from || !to || !fromDate.isValid() || !toDate.isValid()) {
    return undefined;
  }

  return toDate.isSameOrAfter(fromDate) ? undefined : i18n.t('toDateIsBeforeValidationError');
};

/**
 * Validates if date is before today by ms
 * @param {string|number} value Date. Example: 12/12/2021
 * @returns {undefined|string}
 */
export const validateDateAfterToday = (value) => {
  const today = moment();
  const nextDate = moment(value);

  if (!nextDate?.isValid()) {
    return undefined;
  }

  return nextDate.isSameOrBefore(today, 'seconds')
    ? undefined
    : i18n.t('dateIsAfterTodayValidationError');
};

// ** Other ** //

/**
 * Validates if a url is valid
 * @function validationURL
 * @memberof module:FunctionsForReduxForm
 * @param {*} urlString sample of url
 * @returns {string|undefined} Returns string with error. Or undefined if value is valid.
 */
export const validationURL = (urlString) => {
  try {
    if (urlString === undefined || urlString === '') {
      return undefined;
    }
    const url = new URL(urlString);
    return url.protocol === 'http:' || url.protocol === 'https:'
      ? undefined
      : i18n.t('urlIsInvalid');
  } catch (_) {
    return i18n.t('urlIsInvalid');
  }
};

/**
 * Validates if a field is equal to another field
 * @function equals
 * @memberof module:FunctionsForReduxForm
 * @param {*} fieldName Name of another field
 * @returns {function} Validation function
 */
export const equals = (fieldName) => (/** @type {any} */ value, /** @type {{ [x: string]: any; }} */ values) => {
  const compareValue = values[fieldName];
  return value && value === compareValue ? undefined : 'notEqualPass';
};

/**
 * Checks if two fields is NOT equal.
 * @memberof module:FunctionsForReduxForm
 * @param {string} fieldName Name of another field
 * @param {string} message Error message to return if fields are equal
 * @returns {function} Validation function
 */
export const notEqualValue = (fieldName, message) => (/** @type {any} */ value, /** @type {{ [x: string]: any; }} */ values) => {
  const compareValue = values[fieldName];
  return value && value !== compareValue ? undefined : message;
};

/**
 * Validates email
 * @function email
 * @memberof module:FunctionsForReduxForm
 * @param {string} value Value of field
 * @returns {string} Validation function
 */
export const email = (value) => (value
  && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  )
  ? 'invalidMail'
  : undefined);

/**
 * Validates webpage
 * @function webpage
 * @memberof module:FunctionsForReduxForm
 * @param {string} value Value of field
 * @returns {string|undefined} Returns string with error. Or undefined if value is valid.
 */
export const webpage = (value) => (value && !/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/.test(value)
  ? 'invalidWebpage'
  : undefined);

export const zipCode = (countryCode) => (value) => (PostalCodes.validate(countryCode, value) === true ? undefined : 'invalidZip');

/**
 * Validates solar manager id
 * @function gateways
 * @memberof module:FunctionsForReduxForm
 * @param {string} value Value string
 * @returns {string|undefined} Returns string with error. Or undefined if value is valid.
 */
export const gateways = (value) => {
  const min = 3;
  const max = 24;
  const regHex = /^[a-fA-F0-9]+$/;
  const reqMinLength3 = new RegExp(`^[a-fA-F0-9]{${min},}$`);
  const reqMaxLength24 = new RegExp(`^[a-fA-F0-9]{0,${max}}$`);

  const gatewaysList = value
    .trim()
    .split(',')
    .map((gateway) => gateway.trim());

  /**
   * Should be at least one name/smid
   */
  if (!gatewaysList.length) {
    return 'required';
  }

  /**
   * Only hex allowed
   */
  if (gatewaysList.some((gateway) => !regHex.test(gateway))) {
    return 'notValidGatewayNameValidationError';
  }

  /**
   * Min length for each name/smid must be more or equal 3 symbols
   */
  if (gatewaysList.some((gateway) => !reqMinLength3.test(gateway))) {
    return i18n.t('least') + min + i18n.t('symbols');
  }

  /**
   * Min length for each name/smid must be less or equal 24 symbols
   */
  if (gatewaysList.some((gateway) => !reqMaxLength24.test(gateway))) {
    return `${i18n.t('lessOrEqual')} ${max} ${i18n.t('symbols')}`;
  }

  /**
   * Each name/smid should be unique
   */
  if (new Set(gatewaysList).size !== gatewaysList.length) {
    return i18n.t('uniqueGatewaysValidationError');
  }

  return undefined; // need explicitly return undefined in order to have no error in eslint
};

/**
 * Validates IP address
 * @param {string} value Value string
 * @memberof module:FunctionsForReduxForm
 * @returns {string|undefined} Returns string with error. Or undefined if value is valid.
 */
export const validationIP = (value) => (value
  && !/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/.test(
    value
  )
  ? 'invalidIP'
  : undefined);

/**
 * Validates port
 * @param value {number} Input value (should be integer)
 * @returns {undefined|string}
 */
export const validatePort = (value) => (value > 0 && value <= 65535 ? undefined : 'port.validationError');

/**
 * Validates kWp output for general settings
 * @memberof module:FunctionsForReduxForm
 * @param {string} value Value string
 * @returns {undefined|string} Returns string with error. Or undefined if value is valid.
 */
export const validateKWP = (value) => (Number(value) >= 0 || !value ? undefined : 'kWPeakOutputValidationError');

/**
 * Validates if field value is correct for passwords format
 * @memberof module:FunctionsForReduxForm
 * @param {string} value Value string
 * @returns {string|undefined} Returns string with error. Or undefined if value is valid.
 */
export const passwordAdvanced = (value) => {
  if (value.length < 8) {
    return 'validPassword';
  }
  if (!/[a-z]/.test(value)) {
    return 'lowercaseLetter';
  }
  if (!/[A-Z]/.test(value)) {
    return 'UppercaseLetter';
  }
  if (!/[0-9!@#$%^&*)(+=._-]+/.test(value)) {
    return 'symbolContains';
  }
  return undefined;
};

/**
 * Parses string to lower case
 * @memberof module:FunctionsForReduxForm
 * @param {string} value String value
 * @return {string} Lower cased string
 */
export const parseToLowerCase = (value) => (typeof value === 'string' ? value.toLowerCase() : value);

/**
 * Trims string
 * @memberof module:FunctionsForReduxForm
 * @param {string} value String value
 */
export const normalizeTrim = (value) => value.trim();

/**
 * Boolean parser, used for checkboxes
 * @param {*} value
 * @returns {boolean}
 */
export const parseBoolean = (value) => Boolean(value);

/**
 * Validates required fields, with custom warning
 * @function toBeTrueCustomWarning
 * @memberof module:FunctionsForReduxForm
 * @param {string} customWarning Warning message
 * @returns {function} Validation function
 */
export const toBeTrueCustomWarning = (customWarning) => (/** @type {boolean} */ value) => (value === true ? undefined : customWarning);

/**
 * Validates houseFuse output for general settings
 * @memberof module:FunctionsForReduxForm
 * @param {string} value Value string
 * @returns {undefined|string} Returns string with error. Or undefined if value is valid.
 */
export const validateHouseFuse = (value) => {
  const data = Number(value);
  return Number.isSafeInteger(data) && data >= 0 ? undefined : 'houseFuseValidationError';
};

/**
 * @name setDefaultLimitForCtaHeatPumpFields
 * @description validator which set limit for boilerTempOverproduction field in CTA heat pump/CTA heat pump RTU devices
 */
export const setDefaultLimitForCtaHeatPumpFields = (value, values) => {
  const MIN_VALUE = 20;
  const valuesData = {
    [ctaHeatPump]: values?.data?.ctaType,
    [ctaHeatPumpRTU]: values?.data?.ctaTypeRTU,
    [ctaHeatPumpCarel]: values?.data?.ctaTypeCarel
  };

  const defaultFieldValue = ctaHeatPumpInitialValues[values?.device_group]?.[values?.profile_name]?.values
    ?.boilerTempOverproduction
    || ctaHeatPumpInitialValues[values?.device_group]?.[valuesData[values?.device_group]]?.values
      ?.boilerTempOverproduction;
  return (Number(defaultFieldValue) >= Number(value) && Number(value) >= MIN_VALUE)
    ? undefined
    : i18n.t('rangeValidationErrorMessage').replace('{MIN}', MIN_VALUE).replace('{MAX}', defaultFieldValue);
};

export const createRangeValidationForDevice = (device, min, max) => (value, values) => {
  if (values?.device_group !== device) {
    return undefined;
  }

  return (Number(value) >= min && Number(value) <= max)
    ? undefined
    : i18n.t('rangeValidationErrorMessage').replace('{MIN}', min).replace('{MAX}', max);
};

/**
 * Validates if a value of the maximalSocAvailable field is greater than minimalSocAvailable
 * @function validateMinimalSocLessThanMaximalSoc
 * @memberof module:FunctionsForReduxForm
 * @param {number} value Value of field
 * @param {object} allValues all available fields
 * @returns {string|undefined} Returns string with error. Or undefined if value is valid.
 */
export const validateMinimalSocLessThanMaximalSoc = (value, allValues) => {
  const { minimalSocAvailable } = allValues?.data || {};
  return minimalSocAvailable < value ? undefined : 'minimalSocLessThanMaximalSoc';
};

export const validateDecimalPlaces = (decimalPlaces = 2) => (value) => {
  if (!value) return undefined; // return early if value is undefined or null
  const [, decimalPart] = String(value).split('.') || [];
  if (decimalPart && decimalPart.length > decimalPlaces) return i18n.t('decimalPlacesError').replace('{decimal_places}', decimalPlaces);
  return undefined; // return undefined if value is valid
};
