import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  DELETE_DATA_MODAL_ID,
  DELETE_FILES_MODAL_ID,
  DOWNLOAD_FILE_MODAL_ID,
  EDIT_NOTE_MODAL_ID,
  EDIT_ROW_MODAL_ID,
  EDIT_USER_ACCESS_MODAL_ID,
  REMOVE_DATA_MODAL_ID,
  openModalWindow
} from '../../../containers/ModalWindow/slice';
import { EXPERT_MODAL_ID } from '../../../modals';
import { dataListReloadData } from '../../../containers/DataList/slice';
import { usersSensorsRequest } from '../../../containers/DevicesTable/slice';
import { getUserDevicesUser_IdSelector } from '../../../redux-store/selectors/userDevices';
import { EDIT_CAR_MODAL } from '../../../containers/DevicesTable/Tabs/CarsTab/constants';
import { CONFIRM_REMOVE_USER_MODAL_ID } from '../../../modals/DeleteEndUserModalWindow/constants';

import i18n from '../../../i18n';

import '../index.scss';
import { installedSMSendRequest } from '../../../containers/InstalledSM/slice';
import { GATEWAY_USER_ACTIVITY_LOGS_URL } from '../../../api/apiUrls';

/**
 * Render function for React-Table. Column with buttons.
 * @memberof module:ReactTable
 * @param  {Object} props
 * @param  {string} props.id - user id
 * @param  {string} props.device_group - device setting
 * @param  {string} props.type - device type
 * @param  {string} props.ip - device ip
 * @param  {string} props.data - device data
 * @param  {string} props.note - monitoring error note
 * @param  {Object} showBnts - Defines the button what will be shown.
 * @param  {Object} props.inputDevice - input device
 * @param  {string} props.description - description tag
 * @param  {number} props.inputDeviceFunction - load guard function for input device
 * @param  {Object[]} props.affectedDevices - array of affected devices
 */
const RenderButtons = (props, showBnts = {}) => {
  const dispatch = useDispatch();
  const {
    id = null,
    device_group,
    type,
    ip,
    data,
    tag,
    note,
    email,
    sm_id,
    _id,
    inputDevice,
    description,
    inputDeviceFunction,
    affectedDevices,
    deviceActivity = 0,
    notes,
    name,
    vin,
    brand,
    isCarExists,
    gateway,
    capacity,
    orientation,
    tilt,
    permission,
    userName
  } = props;

  const userId = useSelector(getUserDevicesUser_IdSelector);
  const isShowExpertModeModal = useSelector(
    ({ pagesSettings }) => pagesSettings?.modals?.expertModeModalId ?? true
  );

  const openModal = (modalID, dataNew) => dispatch(openModalWindow({ modalID, data: dataNew }));

  const editUserAccess = () => {
    openModal(EDIT_USER_ACCESS_MODAL_ID, { _id, permission });
  };

  const editRowHandle = () => {
    if (isShowExpertModeModal) {
      openModal(EXPERT_MODAL_ID, _id || id);
    }

    dispatch(dataListReloadData({ listID: 'deviceTags' }));

    if (affectedDevices) {
      dispatch(usersSensorsRequest({ userId }));
      return openModal(EDIT_ROW_MODAL_ID, {
        newType: showBnts?.newType,
        type,
        loadGuardDeviceId: _id,
        inputDevice: inputDevice?._id,
        description,
        inputDeviceFunction,
        affectedDevices: affectedDevices.map(({ _id: inputDeviceId }) => inputDeviceId)
      });
    }

    if (type === 'Car') {
      return openModal(EDIT_CAR_MODAL, { _id, name, deviceActivity, data, notes, vin, brand });
    }

    return openModal(EDIT_ROW_MODAL_ID, {
      newType: showBnts?.newType,
      id: _id || id,
      device_group,
      type,
      ip,
      tag: tag?._id,
      tagColor: tag?.color,
      data,
      inputDevice,
      description,
      inputDeviceFunction,
      affectedDevices,
      deviceActivity,
      isCarExists
    });
  };

  const exportOneUser = () => dispatch(installedSMSendRequest({
    method: 'post',
    url: `${GATEWAY_USER_ACTIVITY_LOGS_URL}/${_id}`,
    config: undefined,
    onResult: { errorMessage: 'exportRequestError', successMessage: 'success' }
  }));

  return (
    <>
      {showBnts.exportOneUser && (
        <a
          title={i18n.t('download')}
          onClick={exportOneUser}
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
        >
          <i className="la la-download download-icon" />
        </a>
      )}
      {showBnts.sendEmail && (
        <a
          title={i18n.t('sendEmail')}
          href={`mailto:${email}?subject=${sm_id}`}
          className="send-email-btn btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
        >
          <i id="send-email-icon" className="send-email-icon la la-envelope" />
        </a>
      )}
      {showBnts.editNote && (
        <a
          title={i18n.t('editNote')}
          onClick={openModal.bind(null, EDIT_NOTE_MODAL_ID, { id: _id, note })}
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
        >
          <i className="la la-edit" />
        </a>
      )}
      {showBnts.devices && (
        <Link
          to={`${showBnts.devices.preLink}${id || _id}/`}
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
          title={i18n.t('settings')}
        >
          <i className="la la-cog" />
        </Link>
      )}
      {showBnts.view && (
        <Link
          title={i18n.t('detailsTooltip')}
          to={`${showBnts.view.preLink}${_id || id}`}
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
        >
          <i className="la la-file-text" />
        </Link>
      )}
      {showBnts.editUser && (
        <Link
          to={`${showBnts.editUser.preLink}${_id || id}/`}
          className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
          title={i18n.t('editProfile')}
        >
          <i className="la la-user" />
        </Link>
      )}
      {showBnts.edit && (
        <Link
          to={`${showBnts.edit.preLink}${id || _id}/`}
          className="m-portlet__nav-link btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
          title={i18n.t('editTooltip')}
        >
          <i className="la la-edit" />
        </Link>
      )}
      {showBnts.editRow && (
        <a
          title={i18n.t('editTooltip')}
          onClick={editRowHandle}
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
        >
          <i className="la la-edit" />
        </a>
      )}
      {showBnts.editUserAccess && (
        <a
          title={i18n.t('editTooltip')}
          onClick={editUserAccess}
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
        >
          <i className="la la-edit" />
        </a>
      )}
      {showBnts.removeDevice && (
        <a
          title={i18n.t('delete')}
          onClick={openModal.bind(null, REMOVE_DATA_MODAL_ID, {
            id: _id,
            newType: showBnts?.newType,
            url: showBnts.remove?.preLink
          })}
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill trash"
        >
          <i className="la la-trash" />
        </a>
      )}
      {showBnts.remove && (
        <a
          title={i18n.t('delete')}
          onClick={openModal.bind(null, CONFIRM_REMOVE_USER_MODAL_ID, {
            id: _id,
            email,
            newType: showBnts?.newType,
            url: showBnts.remove?.preLink,
            userName
          })}
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill trash"
        >
          <i className="la la-trash" />
        </a>
      )}
      {showBnts.delete && (
        <a
          title={i18n.t('removeBtn')}
          onClick={openModal.bind(null, DELETE_DATA_MODAL_ID, { id: _id })}
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill trash"
        >
          <i className="la la-close" />
        </a>
      )}
      {showBnts.downloadFile && (
        <a
          title={i18n.t('downloadFile')}
          onClick={openModal.bind(null, DOWNLOAD_FILE_MODAL_ID, { id: _id })}
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
        >
          <i className="la la-download download-icon" />
        </a>
      )}
      {(showBnts.deleteFile || showBnts.deleteInvitedUser) && (
        <a
          title={i18n.t(showBnts.title || 'delete')}
          onClick={openModal.bind(
            null,
            showBnts.modalID || DELETE_FILES_MODAL_ID,
            showBnts.deleteInvitedUser ? { id: _id } : { ids: [_id] }
          )}
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill trash"
        >
          <i className="la la-trash" />
        </a>
      )}
      {showBnts.analysis && (
        <Link
          to={`${showBnts.analysis.preLink}${gateway.owner?._id}/`}
          className="link align-self-center"
          title={i18n.t('analysis')}
        >
          <i className="la la-bar-chart" />
          {' '}
          {i18n.t('analysis')}
        </Link>
      )}
      {showBnts.editString && (
        <a
          title={i18n.t(showBnts.title || 'editString')}
          onClick={openModal.bind(null, showBnts.editString.modalID, { capacity, orientation, tilt, stringId: _id })}
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
        >
          <i className="la la-edit" />
        </a>
      )}
      {(showBnts.editStringPerformance) && (
        <a
          title={i18n.t(showBnts.title || 'editString')}
          onClick={openModal.bind(null, showBnts.editStringPerformance.modalID, { inverterID: showBnts.editStringPerformance.inverterID })}
          className="btn m-btn m-btn--hover-accent m-btn--icon m-btn--icon-only m-btn--pill"
        >
          <i className="la la-edit" />
        </a>
      )}
    </>
  );
};

RenderButtons.propTypes = {
  email: PropTypes.string,
  sm_id: PropTypes.string,
  id: PropTypes.string,
  tag: PropTypes.string,
  device_group: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  ip: PropTypes.string.isRequired,
  note: PropTypes.string,
  data: PropTypes.string.isRequired,
  _id: PropTypes.string,
  vin: PropTypes.string,
  name: PropTypes.string,
  notes: PropTypes.string,
  inputDevice: PropTypes.instanceOf(Object),
  // additionalOptions: PropTypes.instanceOf(Object),
  description: PropTypes.string,
  inputDeviceFunction: PropTypes.string,
  brand: PropTypes.string,
  affectedDevices: PropTypes.instanceOf(Array),
  deviceActivity: PropTypes.number,
  isCarExists: PropTypes.bool,
  gateway: PropTypes.instanceOf(Object),
  permission: PropTypes.string,
  userName: PropTypes.string,
  capacity: PropTypes.string,
  orientation: PropTypes.string,
  tilt: PropTypes.string
};

export default RenderButtons;
