import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import { sortByTime } from '../../../../../utils';

export const TariffStep = ({
  steps,
  highTariff = 0.00,
  lowTariff = 0.00,
  styles = {},
  subStyles = {},
  renderEditComponent = () => null,
  isBlur = false
}) => {
  const sortedSteps = [...steps].sort(sortByTime);

  const blurStyles = {
    opacity: 0.5,
    cursorEvents: 'none'
  };

  return (
    <div style={{
      display: 'flex',
      gap: 2,
      width: '100%',
      transition: 'all 0.3s ease-in-out',
      ...(isBlur ? blurStyles : {})

    }}
    >
      {sortedSteps.map(({ tariff, from, elementIndex }, index) => {
        // get the difference between the current step and the next step if it is the last step then get the difference between the current step and the end of the day
        const diff = index === sortedSteps.length - 1
          ? moment('23:59', 'HH:mm').diff(moment(from, 'HH:mm'), 'minutes')
          : moment(sortedSteps[index + 1].from, 'HH:mm').diff(moment(from, 'HH:mm'), 'minutes');

        // get the percentage of the difference
        const percentage = Math.abs((diff / moment('23:59', 'HH:mm').diff(moment('00:00', 'HH:mm'), 'minutes')) * 100);

        return (
          <div
            key={from}
            style={{
              flexBasis: `${percentage}%`,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: tariff === 'low' ? 'secondary.lowTariff' : 'secondary.highTariff',
              borderRadius: '7px',
              marginBottom: '3px',
              ...styles
            }}
            >
              <span style={{ flex: 1 }}>{tariff === 'low' ? lowTariff : highTariff}</span>
              {renderEditComponent({ from, tariff, elementIndex })}
            </Box>
            <Typography variant="subtitle2" sx={{ fontSize: 10, lineHeight: 1, color: 'secondary.stepSubtitleColor', ...subStyles }}>{from}</Typography>
          </div>
        );
      })}
    </div>
  );
};

TariffStep.propTypes = {
  steps: PropTypes.instanceOf(Array).isRequired,
  highTariff: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lowTariff: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  styles: PropTypes.instanceOf(Object),
  subStyles: PropTypes.instanceOf(Object),
  renderEditComponent: PropTypes.func,
  isBlur: PropTypes.bool
};
